// Dependency
import React from "react";
import { graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Img from "gatsby-image";

// Internationalization
//import i18n from "../../internationalization/i18n";
import { useTranslation } from 'react-i18next';

// Components
import Hero from "../../components/hero/hero";
import Layout from "../../components/layouts";
import CenteredContent from "../../components/centered-content";
import BackgroundCards from "../../components/background-cards";
import TeamCard from "../../components/cards/team-card";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";

// Styles
import * as shopStyles from "./../../templates/pages/shop.module.scss";
import * as ambassadorStyles from "./ambassor.module.scss"
import * as mediaTabsStyles from "../../components/tabs/media-tabs.module.scss";

// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en" ) {
  datoCmsAmbassadorPage (locale: {eq: $locale}) {
    locale
    desktopImage {
      fluid(maxWidth: 1500) {
        ...GatsbyDatoCmsFluid
      }
    }
    mobileImage {
      fluid {
        ...GatsbyDatoCmsFluid
      }
    }
    articles {
      title
      featuredMedia{
          fluid{
            ...GatsbyDatoCmsFluid
        }
      }
    }
    ambassadors {
      name
      title
      image {
        fluid{
            ...GatsbyDatoCmsFluid
        }
      }
    }
    mediaContent {
      backgroundImage {
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      tabContent
      image {
        url
      }
    }
     organisations {
      image {
        url
      }
    }
    seoMetaTags {
      tags
      id
    }
  }
}
`

// Template
const Ambassador = (props) => {
    // Extract page data
    const pageData = props.data.datoCmsAmbassadorPage;
    // Map media
    const mediaData = {
        videoSrcURL: pageData && pageData.desktopVideo ? pageData.desktopVideo.url : '',
        videoSrcURLMobile: pageData && pageData.mobileVideo ? pageData.mobileVideo.url : '',
    }
    const sources = [
        pageData.mobileImage.fluid ,
        {
            ...pageData.desktopImage.fluid ,
            media: `(min-width: 900px)`,
        },
    ]
    // Translation Function
    const { t } = useTranslation();
    // 
    return (
        <Layout seoData={pageData.seoMetaTags} country={props.pageContext.country} location={props.location}>
            <Hero
                sources={sources}
                mediaData={mediaData}
            >
            </Hero>
            <CenteredContent>
                <AnchorLink offset="40" href="#athletes">{t('ambassador.title1')}</AnchorLink>
                <strong>|</strong>
                {pageData && pageData.mediaContent ?
                    <>
                        <AnchorLink offset="40" href="#OS">{t('ambassador.link2')}</AnchorLink>
                        <strong>|</strong>
                    </>
                    : ''}
                <AnchorLink href="#organisations">{t('ambassador.link3')}</AnchorLink>
            </CenteredContent>

            <div className="cd-max-width">
                <section className="">
                    <div id="athletes" className={shopStyles.cdShopHeading}>
                        <h2>{t('ambassador.title1')}</h2>
                        <span></span>
                    </div>

               {/*     <div className={ambassadorStyles.cdAmbassadorBackgroundCards}>
                        {
                            pageData && pageData.articles && <BackgroundCards data={pageData.articles} />
                        }
                    </div>*/}

                    <div className={ambassadorStyles.cdAmbassadorGrid}>
                        {pageData && pageData.ambassadors && pageData.ambassadors.map( (ambassadorCard, index) => {
                            return (
                                <div key={index} className={ambassadorStyles.cdAmbassadorTeamCard}>
                                    <TeamCard data={ambassadorCard} />
                                </div>
                            )
                        } )}
                    </div>
                </section>
                {pageData && pageData.mediaContent ? 
                <section className={ambassadorStyles.cdAmbassadorMediaSection} id="OS">
                    <div className={`${mediaTabsStyles.cdMediaTabsBackgroundImg} cd-background-img`}>
                        {
                            pageData && pageData.mediaContent.backgroundImage && <Img alt="" fluid={pageData.mediaContent.backgroundImage.fluid} />
                        }

                    </div>
                    <div className={`${mediaTabsStyles.cdBackgroundOverlay} cd-background-overlay`}></div>
                    <div className={mediaTabsStyles.cdMediaTabsGrid}>
                        <div className={`${mediaTabsStyles.cdMediaTabsLeft} ${ambassadorStyles.cdAmbassadorMediaLeft}`} >
                            {
                                pageData && pageData.mediaContent.tabContent && <div dangerouslySetInnerHTML={{ __html: pageData.mediaContent.tabContent }}></div>
                            }

                        </div>
                        <div className={mediaTabsStyles.cdMediaTabsRight}>
                            {pageData && pageData.mediaContent.image &&
                                <img src={pageData.mediaContent.image.url} alt="" />
                            }
                        </div>
                    </div>
                </section>:''}

                <section className={ambassadorStyles.cdOrganisationSection} id="organisations">
                    <div className={shopStyles.cdShopHeading}>
                        <h2>{t('ambassador.title2')}</h2>
                        <span></span>
                    </div>
                    <div className={ambassadorStyles.cdOrganisationGrid}>
                        {pageData && pageData.organisations && pageData.organisations.map( (organisation, index) => {
                            return (
                                <div key={index} className={ambassadorStyles.cdOrganisationItem}>
                                    <div className={ambassadorStyles.cdOrganisationItemContent}>
                                        <img src={organisation.image.url} alt="" />
                                    </div>
                                </div>
                            )
                        } )}
                    </div>
                </section>

            </div>

        </Layout>
    )
}

export default Ambassador